
.navigation_mobile{
    background-color: rgb(255,255,255); 
    transition: ease-in-out all .5s;
    transform: translateX(-100vw);
    position: absolute;
}


    .translate{
        transform: translate(0vw);
        position: absolute;
        width: 100vw;
        height: auto;
        overflow: hidden;
        background-color: #ced0d9; 
        padding: 0;
        z-index: -9;
     }


.navigation__submenu{
   display: none;
   transform: scale(0,0);
   transition: all .4s ease-in-out;
}

.link_animation{
        display: block;
        transform: scale(1,1);
        height: auto;
}


.header_nav{
   transition: all .2s ease;
}

.nav__link{
   position: relative;
   transition: all .4s ease-in-out;
}

.nav__sub_link{
   transform-origin: top center;
   display: none;
   transform: scale(0,0);
   transition: all .4s ease-in-out;
}




.header__nav:hover {
   .nav__sub_link{
       display: block;
       transform: scale(1,1);
       padding: .5em 0;
   }
}



.bottom_arrow {
  transform: rotate(180deg) !important;
}