/* REGISTRO PAGE CSS */

body.carousel.registro {
    background-image: url("../images/background/background-registro.jpg");
    min-height: 100vh;
    height: auto;
}

.container.registro {
    width: 100%;
    flex-grow: 1;
    padding: 0 7.7% 0 7.7%/*0 7.7%  0  11%*/
    ;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "FuturaStd-Condensed", sans-serif;
}

.registro .field_span {
    margin-left: 14px;
    display: block;
    min-width: 38%;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.registro_enviar {
    margin-right: 10px;
    float: right;
}

.registro h3 {
    font-family: "FuturaStd-CondensedBold", sans-serif;
    font-size: 28px;
    color: #282828;
}

.registro .text {
    font-size: 13px;
    font-family: "FuturaStd-CondensedBold", sans-serif;
    text-transform: uppercase;
    color: #282828;
    margin-top: 27px;
    margin-bottom: 72px;
}

.registro .lb_img {
    width: 30px;
    height: auto;
}

.registro input {
    vertical-align: middle;
}

.registro .small_text {
    font-size: 7px;
    margin-top: 7px;
}

.registro .medium_text {
    font-size: 10px;
    margin: 0px 0 6px 10px;
    display: block;
}

.registro .small_text input {
    min-width: auto;
}

.registro .uppercase {
    text-transform: uppercase;
}

.registro .d_block {
    display: block;
}

.registro .label_text {
    font-size: 16px;
    font-family: "FuturaStd-CondensedBold", sans-serif;
    text-transform: uppercase;
    color: #282828;
}

.registro .radiogroup {
    display: inline-block;
    text-align: center;
}

.registro .radiogroup label {
    display: block;
    font-size: 12px;
    font-family: "FuturaStd-Condensed", sans-serif;
    text-transform: uppercase;
    color: #282828;
    font-weight: 600;
    margin-top: -8px;
}

.registro .radiogroup input {
    margin: 0;
    width: 25px;
    border: 1px solid #531849;
    opacity: 0;
    height: 25px;
}

.registro .checkboxgroup {
    display: inline-block;
    text-align: center;
}

.registro .checkboxgroup label {
    display: block;
    font-size: 12px;
    font-family: "FuturaStd-Condensed", sans-serif;
    text-transform: uppercase;
    color: #282828;
    font-weight: 600;
    margin-top: -8px;
}

.registro .checkboxgroup input {
    margin: 0;
    width: 40px;
    border: 1px solid #000000;
    opacity: 0;
    height: 25px;
    border-radius: 5px;
}

.registro .float_left {
    float: left;
}

.registro .float_right {
    float: right;
}

.container .registro picture {
    max-width: 60%;
}

.field_mobile input[type="checkbox"] {
    width: auto;
    display: inline-block;
}

.right_textbox {
    float: right;
    text-align: left;
    height: 45px;
    margin-left: 12px !important;
    @media only screen and (max-width: 1030px) {
        margin-left: 12px !important;
    }
    @media only screen and (max-width: 800px) {
        margin-left: 0px !important;
    }
}


/* The Checkbox & Radio Button Customized */

.checkboxgroup {
    display: inline-block;
    position: relative;
    padding-left: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 3px;
}

.radiogroup {
    display: inline-block;
    position: relative;
    padding-left: 0px;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.registro .checkboxgroup .medium_text {
    font-size: 10px;
    margin: -6px 0 6px -10px;
    display: block;
}

.registro .radiogroup .medium_text {
    font-size: 12px;
    margin: -6px 0 6px 10px;
    display: block;
}

.checkboxgroup input {
    min-width: 30px;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.radiogroup input {
    min-width: 55px;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkboxgroup .checkmark {
    position: absolute;
    top: 0;
    left: -2px;
    height: 13px;
    width: 25px;
    background-color: transparent;
    border: 1px solid #531849;
}

.radiogroup .checkmark {
    position: absolute;
    top: 0;
    left: 15px;
    height: 15px;
    width: 45px;
    background-color: transparent;
    border: 1px solid #531849;
    border-radius: 5px;
    @media (max-width: 676px) {
        width: 55px;
        height: 30px;
    }
}

.checkboxgroup:hover input~.checkmark,
.radiogroup:hover input~.checkmark {
    background-color: transparent;
}

.checkboxgroup input:checked~.checkmark,
.radiogroup input:checked~.checkmark {
    background-color: transparent;
}

.checkboxgroup .checkmark:after,
.radiogroup .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkboxgroup input:checked~.checkmark:after,
.radiogroup input:checked~.checkmark:after {
    display: block;
}

.checkboxgroup .checkmark:after {
    left: 9px;
    top: -2px;
    width: 4px;
    height: 8px;
    border: solid #531849;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.radiogroup .checkmark:after {
    left: 18px;
    top: -2px;
    width: 5px;
    height: 10px;
    border: solid #531849;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox_terms .checkboxgroup {
    display: inline-block;
    position: relative;
    padding-left: 0px;
    margin-top: 2px;
    margin-bottom: 16px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox_terms .checkboxgroup input {
    min-width: 15px;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox_terms .checkboxgroup label {
    display: inline-block;
    font-size: 12px;
    font-family: "FuturaStd-Condensed", sans-serif;
    text-transform: uppercase;
    color: #282828;
    font-weight: 600;
    margin: 0;
    padding: 0;
    @media (max-width: 325px) {
        font-size: 11px;
    }
}

.checkbox_terms .checkboxgroup .checkmark {
    position: absolute;
    top: 0;
    left: 0px;
    height: 20px;
    width: 25px;
    background-color: transparent;
    border: 1px solid #531849;
}

.checkbox_terms .checkboxgroup .checkmark:after {
    left: 9px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid #531849;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.small_text .checkboxgroup {
    display: inline-block;
    position: relative;
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.small_text .checkboxgroup input {
    min-width: 30px;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
}

.small_text .checkboxgroup label {
    display: inline-block;
    font-size: 7.5px;
    font-family: "FuturaStd-Condensed", sans-serif;
    text-transform: uppercase;
    color: #282828;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 4px;
}

.small_text .checkboxgroup:hover input~.checkmark {
    background-color: transparent;
}

.small_text .checkboxgroup input:checked~.checkmark {
    background-color: transparent;
}

.small_text .checkboxgroup .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.small_text .checkboxgroup input:checked~.checkmark:after {
    display: block;
}

.small_text .checkboxgroup .checkmark:after {
    left: 6px;
    top: -2px;
    width: 4px;
    height: 8px;
    border: solid #531849;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.small_text .checkboxgroup .checkmark {
    position: absolute;
    top: 4px;
    left: 0px;
    height: 13px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #531849;
}

.small_text .checkboxgroup label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #531849;
    padding: 5px 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.small_text .checkboxgroup input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 6px;
    width: 6px;
    height: 10px;
    transform: rotate(45deg);
    border: solid #531849;
    border-width: 0 2px 2px 0;
}

.checkbox_terms .checkboxgroup label {
    position: relative;
}

.checkbox_terms .checkboxgroup input {
    display: none;
}

.checkbox_terms .checkboxgroup label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #531849;
    padding: 6px 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.checkbox_terms .checkboxgroup input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 5px;
    width: 5px;
    height: 10px;
    transform: rotate(45deg);
    border: solid #531849;
    border-width: 0 2px 2px 0;
}

.gender-checkboxes label {
    position: relative;
}

.gender-checkboxes input {
    display: none;
}

.gender-checkboxes label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #531849;
    padding: 14px 19px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    display: block;
    margin-right: -4px;
    margin-bottom: 5px;
    border-radius: 5px;
    @media (max-width: 713px) {
        width: 15px;
    }
}

.gender-checkboxes input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    left: 17px;
    width: 5px;
    height: 10px;
    transform: rotate(45deg);
    border: solid #531849;
    border-width: 0 2px 2px 0;
    @media (max-width: 713px) {
        left: 25px;
    }
}

.date-checkbox label {
    position: relative;
}

.date-checkbox input {
    /*display: none;*/
    opacity: 1 !important;
    min-width: 30px !important;
    height: 30px !important;
    margin: 0 0 4px 0 !important;
    border: 1px solid #531849 !important;
    font-size: 0.6rem !important;
    text-align: center;
}


/*.date-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #531849;
  padding: 6px 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  display: block;
  margin-right: 5px;
  margin-bottom: 5px;
}

.date-checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 5px;
  height: 10px;
  transform: rotate(45deg);
  border: solid #531849;
  border-width: 0 2px 2px 0;
}*/

.registro .checkboxgroup .medium_text {
    margin: 0;
}

.registro input {
    min-width: 180px;
    flex-grow: 1;
    margin: 0.8em;
    font-family: "FuturaStd-Medium";
    letter-spacing: 1px;
    font-size: 1.05em;
}

.registro input::-webkit-input-placeholder,
.field_second input::-webkit-input-placeholder {
    color: #282828;
    font-family: "FuturaStd-CondensedBold";
}

.registro input::-moz-placeholder,
.field_second input::-moz-placeholder {
    color: #282828;
    font-family: "FuturaStd-CondensedBold";
}

.registro input:-ms-input-placeholder,
.field_second input:-ms-input-placeholder {
    color: #282828;
    font-family: "FuturaStd-CondensedBold";
}

.registro input::-ms-input-placeholder,
.field_second input::-ms-input-placeholder {
    color: #282828;
    font-family: "FuturaStd-CondensedBold";
}

.registro input::placeholder,
.field_second input::placeholder {
    color: #282828;
    font-family: "FuturaStd-CondensedBold";
}

.document_select {
    background: transparent;
    border: none;
}

.field_document {
    width: 48%;
}

@include mq(tablet-wide,
min) {
    .field_span.field_document {
        margin: 0.8rem;
    }
    input.right_textbox {
        margin-left: 0 !important;
    }
    .field_span.float_left.field_date {
        margin: 0.8rem;
        min-width: 48%;
    }
    .registro .checkboxgroup:first-child {
        padding-left: 0;
    }
    .container.registro .label_text.genero {
        margin-left: 0;
    }
    .field_span.float_left {
        margin: 0.8rem;
        margin-left: -4%;
    }
    .gender-checkboxes .radiogroup:first-child .medium_text {
        margin-left: 0;
    }
}

.field_date {
    width: 42%;
}

.field_content {
    display: flex;
    align-items: center;
}

.field_content label {
    width: auto !important;
}

.field_img {
    padding-right: 12px;
}

.field_content input {
    border: none;
    border-bottom: solid 1px;
    border-radius: inherit;
    border-color: #6e0854;
    margin: 0;
    padding: 0;
}

.icon_content {
    display: flex;
}

.input_container {
    width: 100%;
}

@media screen and (max-width: 713px) {
    .container.registro picture {
        width: 85%;
        margin-bottom: 1em;
    }
    .document_select {
        margin-top: 10px;
        position: relative;
        right: 3px;
    }
    label {
        color: #282828;
    }
    .registro input {
        min-width: auto;
    }
    .registro .checkboxgroup input {
        width: 55px;
    }
    .registro .radiogroup input {
        z-index: 9;
        position: relative;
        width: 59px;
    }
    .terms_input {
        height: 10px !important;
        display: block !important;
        position: relative;
        right: 20px;
        top: 15px;
        z-index: 9999;
        width: 100% !important;
    }
    .registro input {
        margin: auto;
    }
}

.container.registro picture img {
    margin: 0 auto;
}

@media screen and (min-width: 713px) {
    .container.registro {
        min-height: 550px;
    }
}

@media screen and (max-width: 800px) {
    .container.registro {
        flex-direction: column-reverse;
        height: auto;
        padding: 0 5%;
        overflow-x: hidden;
    }
}

.container.registro .form.desktop {
    width: 100%;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 813px) {
    .container.registro .form.desktop {
        font-size: 10px !important;
        margin: 2em 0;
    }
}

@media screen and (max-width: 713px) {
    .container.registro .form.desktop {
        width: 100%;
        height: auto;
        // display: none;
    }
    .field_firts,
    .field_second {
        flex-direction: column;
    }
    .registro .lb_img {
        display: none;
    }
    .registro input {
        width: 100%;
        margin-bottom: 2.5rem;
        font-size: 1.4em;
        margin-left: 0 !important;
    }
    .registro .field_span {
        margin-left: 0;
        min-width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .registro .small_text {
        width: 100%;
    }
    .small_text .checkboxgroup {
        width: 100%;
        text-align: left;
    }
    .document_select {
        width: 100%;
        border: solid #6e0854 1px;
        border-radius: 10px;
        padding: 10px 10px;
        margin: 1em 0;
        background: transparent;
    }
    .registro .checkboxgroup:first-child {
        padding-left: 0;
    }
    .container.registro .label_text.genero {
        margin-left: 0 !important;
    }
    .gender-checkboxes .radiogroup:first-child .medium_text {
        margin-left: 0;
        @media (max-width: 676px) {
            margin-left: 10px;
        }
    }
    .submit_content {
        display: flex;
        flex-direction: column;
        margin-left: 0;
    }
    .checkbox_terms .checkboxgroup {
        padding-left: 0;
        text-align: left;
        font-size: 11px;
    }
}

.container.registro {
    .label_text.genero {
        margin-left: 11%;
        @media (max-width: 1030px) {
            margin-left: 8%;
        }
    }
    .lb_img {
        @media only screen and (min-width: 1000px) {
            display: none;
        }
    }
    @media only screen and (max-width: 768px) {
        .field_img {
            display: none;
        }
        .label_text.fecha {
            margin-left: 23px;
        }
        .label_text.genero {
            margin-left: 21%;
        }
    }
}

.gender-checkboxes {
    margin-left: 11%;
    @media (max-width: 1030px) {
        margin-left: 12px;
    }
    @media (max-width: 800px) {
        margin-left: 14%;
    }
    @media (max-width: 713px) {
        margin-left: 0%;
    }
}

@media (max-width: 676px) {
    #radio {
        margin-left: -9px;
    }
    .registro .radiogroup input {
        height: 45px;
    }
    .registro .radiogroup .medium_text {
        margin: -6px 0 6px 10px;
    }
    .radiogroup .checkmark:after {
        left: 23px;
        top: 5px;
    }
}

.register_logo {
    width: 400px;
    height: 400px;
    @media (max-width: 450px) {
        width: 400px;
        height: 400px;
    }
    @media (max-width: 360px) {
        width: 300px;
        height: 300px;
    }
    img {
        max-width: initial;
        width: 400px;
        height: 400px;
        background-size: 400px;
        background-position: top;
        background-repeat: no-repeat;
        margin: auto;
        @media (max-width: 450px) {
            width: 400px;
            height: 400px;
            background-size: cover;
        }
        @media (max-width: 360px) {
            width: 300px;
            height: 300px;
        }
    }
}

.politics_content {
    h2 {
        font-weight: bold;
    }
    h1 {
        font-weight: bold;
        color: rgb(42, 81, 165);
        line-height: 30px !important;
    }
    @media (max-width: 600px) {
        padding: 0;
        margin: 3% 6%;
    }
}

.politics_order {
    list-style: lower-alpha;
    li {
        padding: 7px 0;
    }
}


/*# sourceMappingURL=maps/main.css.map */