.products_page {
    background-image: url("../images/background/products/back-forte.jpg");
    background-size: cover;
    background-position: center;
    &.forte {
        background-image: url("../images/product_page/bg-desktop-forte.jpg");
        @media screen and (max-width: 713px) {
            background-image: url("../images/product_page/bg-mobile-red.jpg");
        }
    }
    &.migran {
        background-image: url("../images/product_page/bg-desktop-migran.jpg");
        @media screen and (max-width: 713px) {
            background-image: url("../images/product_page/bg-mobile-green.jpg");
        }
    }
    &.ultra {
        background-image: url("../images/product_page/bg-desktop-ultra.jpg");
        @media screen and (max-width: 713px) {
            background-image: url("../images/product_page/bg-mobile-purple.jpg");
        }
    }
}

.container-banner {
    padding: 0 1.5%;
    padding-top: 1.5em;
    margin: 0 0 1em 0;
    img {
        height: 400px;
        width: 400px;
        @media (max-width: 713px) {
            height: 300px;
            width: 300px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0;
        margin-bottom: 2em;
        flex-grow: 0.2;
        justify-content: center;
        align-items: flex-end;
    }
    @media screen and (max-width: 713px) {
        margin-bottom: 1em;
        padding: 0;
    }
}

.container-grid-products {
    display: flex;
    padding: 0 1.5%;
    justify-content: space-between;
    & .column {
        display: flex;
        flex-direction: column;
    }
    & .column:nth-child(1) {
        flex-basis: 27%;
        margin-bottom: 3em;
    }
    & .column:nth-child(2) {
        flex-basis: 50%;
    }
    & .column:nth-child(3) {
        flex-basis: 14.4%;
    }
    @media screen and (max-width: 1024px) {
        flex-grow: 1;
    }
    @media screen and (max-width: 713px) {
        padding: 0;
    }
}

p,
ul,
li,
a,
h1,
h2,
h3 {
    text-decoration: none;
    font-family: "Helvetica-Regular";
}

.title-product {
    text-align: end;
    margin-bottom: 1.5em;
    font-family: "Helvetica-Regular";
    color: #fff;
    letter-spacing: 1.5px;
    padding: 0.6em 1em;
    border-radius: 10px;
    &.forte {
        background-color: #8d3131;
        color: #fff;
    }
    &.migran {
        background-color: #0d3f3a;
    }
    &.ultra-forte {
        background-color: #6f1762;
    }
    >span {
        &.forte,
        &.yelow {
            color: #d1c277;
        }
        &.forte-mobile {
            color: #9c3030;
        }
    }
    &.mobile {
        background-color: transparent;
        color: #9d9e9c;
        text-align: center;
        margin: 0 0 2.5em 0;
        font-family: "Helvetica-Bold";
        letter-spacing: 0.5px;
    }
}

.show {
    background-color: #d3d7db;
    color: #9d9e9c;
    text-align: end;
    margin: 0 2em 1em 0;
    padding: 0.5em 1em;
    border-radius: 10px;
    transition: all 0.2s ease-out;
    &.active-forte {
        color: #8d3131;
    }
    &.active-migran {
        color: #0d3f3a;
    }
    &.active-ultraforte {
        color: #6f1762;
    }
    &:hover {
        transform: scale(1.03);
    }
    &:active {
        transform: scale(1);
    }
}

.show-title {
    font-family: "Helvetica-Bold", sans-serif;
    font-size: 1.2em;
    &.forte {
        color: #9c3030;
    }
}

.show-p {
    font-family: "Helvetica-Light", sans-serif;
    line-height: 1.4;
    color: #000;
    margin: 0.5em 0;
    font-size: 0.92em;
    &.mobile {
        font-family: "Helvetica-Condensed";
        b {
            font-family: "Helvetica-Condensed-md";
        }
    }
}

.hidden {
    display: none;
}

.column.third {
    img {
        margin: 0 0 1em 0;
    }
}

.container-grid-products>.column {
    @media screen and (max-width: 713px) {
        display: none;
    }
}

.article-mobile {
    height: auto;
    width: 100%;
    padding: 0 5% 2em 5%;
    @media screen and (min-width: 714px) {
        display: none;
    }
}

.container-banner {
    picture {
        width: 100%;
    }
}

.migran {
    color: #0d3f3a;
}

.forte {
    color: #9c3030;
}

.ultra-forte {
    color: #6f1762;
}

.column-logo-mobile {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    justify-content: center;
    align-items: center;
    & a {
        margin: 0.5em 0;
    }
}

.products_content {
    width: 40%;
    @media (max-width: 650px) {
        width: 100%;
        margin-top: -5em;
        padding: 15px 0;
    }
    a {
        text-align: center;
    }
}

.img_wrap.ibuflash_img {
    width: auto;
    margin: auto;
    margin-right: 7%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 1024px) {
        margin-right: auto;
    }
    @media only screen and (max-width: 420px) {
        width: 400px;
    }
    figure {
        &:first-child {
            width: 350px;
            height: 4350px;
            margin: auto;
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 1.2em;
            @media only screen and (max-width: 420px) {
                width: 300px;
                height: 300px;
            }
            @media only screen and (max-width: 380px) {
                width: 270px;
                height: 3270px;
            }
            img {
                width: 350px;
                height: 350px;
                margin: auto;
                z-index: -1;
                position: relative;
                right: 1.2em;
                @media only screen and (max-width: 420px) {
                    width: 300px;
                    height: 300px;
                }
                @media only screen and (max-width: 380px) {
                    width: 270px;
                    height: 270px;
                }
            }
        }
        /*&:nth-child(2) {
             position: absolute;
            margin-top: -25em;
            margin-left: 15em; */
        /* @media only screen and (max-width: 650px){
                margin-left: 9em;
                margin-top: -25em;
            }
            @media only screen and (max-width: 375px){
                margin-left: 13em;
                margin-top: -22em;
            } 
        }*/
    }
    /* .ibuflash_capsula{
        img{
            width: 400px;
            margin-left: 9em;
            margin-top: -28em;
            position: absolute;
            @media only screen and (max-width: 1024px){
                top: 3em;
                left: 20em;
            }
            @media only screen and (max-width: 768px){
                top: 6%;
                left: 24%;
            }
            @media only screen and (max-width: 650px){
                width: 300px;
                top: 4.3em;
                left: 4em;
            }
            @media only screen and (max-width: 375px){
                left: 3em;
            }
            @media only screen and (max-width: 325px){
                left: 1em;
            }
        }
    } */
    .ibuflash_sombra {
        width: 350px;
        height: 350px;
        margin: auto;
        background-size: contain;
        background-repeat: no-repeat;
        @media (max-width: 420px) {
            width: 300px;
            height: 300px;
        }
        @media (max-width: 380px) {
            width: 270px;
            height: 270px;
        }
    }
}

.products_banner {
    background-size: cover;
    @media only screen and (max-width: 1024px) {
        overflow: hidden;
        display: flex;
        justify-content: center;
    }
    .back_img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 400px;
        width: 400px;
        margin: auto;
        @media (max-width: 713px) {
            height: 300px;
            width: 300px;
        }
    }
}

.container-products {
    .products {
        background-size: contain;
        background-position-y: 0;
        background-position-x: 0;
        background-repeat: no-repeat;
    }
}

.item-carousel {
    .text-wrap {
        @media only screen and (max-width: 713px) {
            text-align: center;
        }
        p {
            @media screen and (max-width: 450px) {
                width: 70%;
                margin: auto;
            }
            @media screen and (max-width: 375px) {
                width: 60%;
                margin: auto;
            }
        }
        .btn_video {
            @media screen and (max-width: 450px) {
                margin-top: 20px;
            }
        }
    }
}