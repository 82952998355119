.item-carousel {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end;
    @media screen and (max-width: 1024px) {
        height: auto;
        flex-direction: column;
    }
    .img_wrap {
        @media screen and (max-width: 1024px) {
            width: 90%;
        }
        @media screen and (max-height: 813px) {
            width: 50%;
        }
        @media screen and (max-width: 713px) {
            width: 100%;
            margin: 1em 0;
        }
    }
}

.item-carousel.frist {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
    .text-wrap.first {
        width: 30%;
        @media screen and (max-width: 1024px) {
            width: 100%;
            margin-bottom: 3em;
        }
        @media screen and (max-width: 713px) {
            order: 2;
            margin: 1em 0 0 0;
            >p {
                margin: auto;
                padding-top: 1em;
                width: 70%;
            }
        }
        @media screen and (max-width: 375px) {
            margin: 0;
        }
    }
    .img_wrap {
        width: 60%;
        display: block;
        height: auto;
        padding: 1em;
        @media screen and (max-width: 1024px) {
            width: 90%;
            padding: 2em 0 1em 0;
        }
        @media screen and (max-height: 1036px) {
            width: 70%;
        }
        @media screen and (max-width: 713px) {
            width: 100%;
            margin: 1em 0;
        }
    }
}

.text-wrap {
    h2 {
        font-family: "FuturaStd-CondensedBold";
        font-size: 4em;
        color: $purple;
        margin: 1em 0 0 0;
        line-height: 1.1;
        letter-spacing: 2px;
        @media screen and (max-width: 713px) {
            width: 100%;
            margin: 0em 0 0 0;
            font-size: 2.3em;
        }
        @media screen and (max-width: 375px) {
            font-size: 1.3em;
        }
        &.migran {
            color: #34494c;
            span {
                color: #426c72;
            }
        }
        &.red {
            color: #8d0003;
            span {
                color: #b51b2f;
            }
        }
        @media screen and (max-width: 713px) {
            text-align: center;
        }
    }
    p {
        font-family: "Helvetica-Light";
        line-height: 1.6;
        font-size: 1em;
        padding: 0 25% 0 1em;
        color: black;
        letter-spacing: 1px;
        @media screen and (max-width: 375px) {
            font-size: .7em;
        }
        border-left: 3px solid #000;
        @media screen and (max-height: 1036px) {
            margin: .5em 0;
        }
        @media screen and (max-width: 713px) {
            width: 70%;
            padding: 0;
            border: none;
            margin: auto;
        }
        @media screen and (min-width: 1500px) {
            font-size: 1.3em;
            margin-top: 1em;
        }
    }
}

.img_descrip {
    font-family: "Helvetica-Condensed";
    text-align: end;
    font-size: 1.3em;
    letter-spacing: 1.5px;
}


/* //////////////////////////////////////////////////////////////////////////////   Container carousel   ////////////////////////////////////////////////////////////////*/

.content-carousel {
    width: 100%;
    padding: 0 5% 0 0;
    height: auto;
    @media screen and (max-width: 1024px) {
        width: 100%;
        padding: 0 10%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.video {
        @media screen and (max-width: 1024px) {
            align-items: center;
        }
    }
}

.content-carousel.first {
    padding: 0 5% 0 10%;
    @media screen and (min-width: 713px) {
        display: flex;
        align-items: center;
    }
    align-items: center;
}

.text-wrap {
    @media screen and (min-width: 1025px) {
        width: 45%;
        padding: 0 0 0 1em;
    }
    @media screen and (min-width: 1325px) {
        width: 40%;
        //padding: 0 0 0 1em;
        margin-right: 2em;
    }
}