@import 'abstracts/variables';
@import 'abstracts/mixins';  
@import 'base/typography'; 
@import 'base/reset'; 

@import 'layout/grid.scss';
@import 'layout/grid_products.scss';
@import 'layout/grid_products_responsive.scss';
@import 'components/section_carousel.scss';
@import 'components/lightbox';
@import 'components/btn_video';
@import 'components/_video_content';
@import 'components/_carousel_second';
@import 'components/responsive.scss';
@import 'components/header.scss';
@import 'components/footer';
@import 'components/track';
@import 'pages/product-forte';
@import 'pages/contacto';
@import 'pages/dolor.scss';
@import 'pages/compras';

@import 'layout/animation';
@import 'components/_terminos_condiciones.scss';
@import 'pages/_register';



.carousel-cell{
	background-color: coral;
	display: flex;
	width: 100%;
	height: 300px;
}

.carousel-cell:nth-child(even){
background-color: darkcyan;
}