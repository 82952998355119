.term_content{
    padding: 0 7%;
    margin-top: 3%;
    @media (max-width: 600px){
        padding: 0 3%;
    }

    .content{
        line-height: 20px;
        margin-bottom: 2%;
        h1{
            font-size: 30px;
            text-align: center;
            margin-bottom: 30px;
            line-height: 40px;
            @media (max-width: 600px){
                font-size: 25px;
            }
        }
        h2{
            font-size: 17px;
            margin: 1.5% 0;
        } 
    }
}