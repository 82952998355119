


/*Futura Medium*/
@font-face {
    font-family: "FuturaStd-Medium";
    src: url("../fonts/FuturaStd-Medium.eot");
    src: url("../fonts/FuturaStd-Medium.eot?#iefix") format("embedded-opentype"),
      url("../fonts/FuturaStd-Medium.otf") format("opentype"),
      url("../fonts/FuturaStd-Medium.svg") format("svg"),
      url("../fonts/FuturaStd-Medium.ttf") format("truetype"),
      url("../fonts/FuturaStd-Medium.woff") format("woff"),
      url("../fonts/FuturaStd-Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  /*Futura Heavy*/
  @font-face {
    font-family: "FuturaStd-Heavy";
    src: url("../fonts/FuturaStd-Heavy.eot");
    src: url("../fonts/FuturaStd-Heavy.eot?#iefix") format("embedded-opentype"),
      url("../fonts/FuturaStd-Heavy.otf") format("opentype"),
      url("../fonts/FuturaStd-Heavy.svg") format("svg"),
      url("../fonts/FuturaStd-Heavy.ttf") format("truetype"),
      url("../fonts/FuturaStd-Heavy.woff") format("woff"),
      url("../fonts/FuturaStd-Heavy.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  
  /*Futura Condense Bold*/
  @font-face {
    font-family: "FuturaStd-CondensedBold";
    src: url("../fonts/FuturaStd-CondensedBold.eot");
    src: url("../fonts/FuturaStd-CondensedBold.eot?#iefix") format("embedded-opentype"),
      url("../fontsFuturaStd-CondensedBold.otf") format("opentype"),
      url("../fonts/FuturaStd-CondensedBold.svg") format("svg"),
      url("../fonts/FuturaStd-CondensedBold.ttf") format("truetype"),
      url("../fonts/FuturaStd-CondensedBold.woff") format("woff"),
      url("../fonts/FuturaStd-CondensedBold.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  
  /*Helvetica Regular*/
  @font-face {
    font-family: "Helvetica-Regular";
    src: url("../fonts/Helvetica-Regular.eot");
    src: url("../fonts/Helvetica-Regular.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Helvetica-Regular.otf") format("opentype"),
      url("../fonts/Helvetica-Regular.svg") format("svg"),
      url("../fonts/Helvetica-Regular.ttf") format("truetype"),
      url("../fonts/Helvetica-Regular.woff") format("woff"),
      url("../fonts/Helvetica-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }


    /*Helvetica Bold*/
  @font-face {
    font-family: "Helvetica-Bold";
    src: url("../fonts/Helvetica-Bold.eot");
    src: url("../fonts/Helvetica-Bold.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Helvetica-Bold.otf") format("opentype"),
      url("../fonts/Helvetica-Bold.svg") format("svg"),
      url("../fonts/Helvetica-Bold.ttf") format("truetype"),
      url("../fonts/Helvetica-Bold.woff") format("woff"),
      url("../fonts/Helvetica-Bold.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }





  @font-face {
    font-family: "Helvetica-Light";
    src: url("../fonts/Helvetica-Light.eot");
    src: url("../fonts/Helvetica-Light.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Helvetica-Light.otf") format("opentype"),
      url("../fonts/Helvetica-Light.svg") format("svg"),
      url("../fonts/Helvetica-Light.ttf") format("truetype"),
      url("../fonts/Helvetica-Light.woff") format("woff"),
      url("../fonts/Helvetica-Light.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Helvetica-Condensed";
    src: url("../fonts/Helvetica-Condensed.eot");
    src: url("../fonts/Helvetica-Condensed.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Helvetica-Condensed.otf") format("opentype"),
      url("../fonts/Helvetica-Condensed.svg") format("svg"),
      url("../fonts/Helvetica-Condensed.ttf") format("truetype"),
      url("../fonts/Helvetica-Condensed.woff") format("woff"),
      url("../fonts/Helvetica-Condensed.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  

  @font-face {
    font-family: "Helvetica-Condensed-md";
    src: url("../fonts/HelveticaNeue-MediumCond.eot");
    src: url("../fonts/HelveticaNeue-MediumCond.eot?#iefix") format("embedded-opentype"),
      url("../fonts/HelveticaNeue-MediumCond.otf") format("opentype"),
      url("../fonts/HelveticaNeue-MediumCond.svg") format("svg"),
      url("../fonts/HelveticaNeue-MediumCond.ttf") format("truetype"),
      url("../fonts/HelveticaNeue-MediumCond.woff") format("woff"),
      url("../fonts/HelveticaNeue-MediumCond.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  

