.container-products-mobile{
    display: none;
    flex-direction: column;
    justify-content: content;

    @media screen and (max-width: 713px) {
       display: flex;
    }

    article{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
        min-height: 230px;

        figure{
            margin: 0;
            background-size: contain;
            width: 500px;
            height: 500px;
            @media only screen and (max-width: 650px){
                background-position: -12px;
            }
            img{
                width: 500px;
                height: 500px;
                margin: auto;
            }
        }
    }

    a{
        width: max-content;
        background-color: #7c3577;
        color: #fff;
        font-family: "FuturaStd-Medium";
        padding: 1em 1.2em;
        display: inline;
        font-size: .6em;
        letter-spacing: 1px;
        text-decoration: none;

        position: relative;
        bottom: 25%;
        left: 25%;
        border-radius: 20px;
    }
}

.btn_arrow.bottom, .btn_arrow.top {
    @media screen and (max-width: 1024px) {
        width: 35px;
        display: block;
    }

} 
        .btn_arrow.bottom {
            @media screen and (max-width: 713px) {
                transform: rotate(-90deg);
            }
          
        }

        .btn_arrow.top {
            @media screen and (max-width: 713px) {
                transform: rotate(-90deg);
            } 
        }

.btn_mb_migran{
    background-color: #34494c !important;
}

.btn_mb_forte{
    background-color: #b51b2f !important
}