
.container.content-second{
    display: flex;
    padding: 0 5% 0 5%;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
    
    #carousel{
        width: 95%;
    }
}


.next,.prev{
    opacity: 0.4 !important;
}

.carousel_second_wrap.second{
    width: 95%;
}

.container.content-second #carousel{
    width: 100%;
}



.carousel_video_wrap{
    display: flex; 
    justify-content: center;
}


li.items{
    border-radius: 15px;
   
    
    @media screen and (max-width: 1200px) {
        width: auto; 
        height: auto;
    }
}

.item_video{
    width: 100%;
    height: 100%;

  
 

    @media screen and (max-width: 1200px) {
        width: 462px; 
        height: 259px;
    }

    @media screen and (max-width: 813px) {
        width: 330px;
        height: 183px;
    }
}



.buttons.video{

    margin: 0;

    @media screen and (max-width: 713px){
        margin: 0 0 2.5em 0;
    }

    button{
        background-color: transparent;
        padding: 0 !important;
        display: flex;
        margin: 0 2em !important;

        &:focus{
            color: none;
            border: none;
            background: none;
        }

        @media screen and (max-width: 713px) {
            margin: 0 0 0 0; 
        }

        &#prev{
            transform: rotate(270deg) !important;
        }
    
        &#next{
            transform: rotate(-90deg) !important;
        }
    }

   
}





.container.content-second.video{
    @media screen and (max-width: 713px){
        padding: 0;
    }

    .carousel_second_wrap.second{
        @media screen and (max-width: 713px){
            padding: 0;
            width: 100%;
        }
    }
}

       





.video_frame{
    width: 476px;
    height: 267px;

    @media screen and (max-width: 713px) {
        width: 276px;
        height: 267px;
    }
}   




#carousel div.nextRightSecond{
    @media screen and (max-width: 713px){
        transform: scale(0.7);
    }   
}

#carousel div.selected{
    @media screen and (max-width: 713px){

    }
}


#carousel div.prevLeftSecond {
    @media screen and (max-width: 713px){

    }
}


.background-ibuflash-forte.index{
    @media only screen and (min-width: 1025px){
        height: 100vh;
    }
}


.swiper-container {
    width: 100%;
    height: 100%;

    .btn_swiper{
        position: relative;
        left: 96%;
        top: -100%;
        width: 1%;
        @media only screen and (max-width: 1024px){
            width: 100%;
            left: 0;
            top: 0;
            align-items: center;
        }
        .swiper-pagination{
            position: initial;
            font-size: 0;
            .swiper-pagination-bullet{
                height: 12px;
                width: 12px;
                margin: 10px 0;
                &-active{
                    background-color: #7c3577;
                }
                @media only screen and (max-width: 1024px){
                    height: 15px;
                    width: 15px;
                    margin: 5px 12px;
                }
            }
        }

        a{
            position: inherit;
            margin: 0;
            &::after{
                display: none;
            }
            &:first-child{
                position: initial;
                left: -8px; 
            }
            @media only screen and (max-width: 1024px){
                &:nth-child(3){
                    position: relative;
                    left: 7px;
                }
            }
        }

        .swiper-button-next, .swiper-button-prev{
            position: initial;
        }
    }

    .lightbox-container{
        position: absolute;
        display: flex;
        height: 100vh;
        width: 100vw;
        z-index: 999;
        align-items: baseline;
        @media only screen and (max-width: 1024px){
            align-items: center;
            height: auto;
            background-color: #100f0f9c;
        }

        iframe{
            height: 90% !important;
            @media only screen and (max-width: 1024px){
                height: 100% !important;
            }
        }

        .lightbox-video-wrapper{
            @media only screen and (max-width: 1024px){
                height: 20em;
            }
        }
    }
  }
  .swiper-slide {

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  