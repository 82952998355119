@mixin font-face($font-name, $font-path){
    /*Futura Medium*/
@font-face {
    font-family: "FuturaStd-Medium";
    src: url("./fonts/FuturaStd-Medium.eot");
    src: url("./fonts/FuturaStd-Medium.eot?#iefix") format("embedded-opentype"),
      url("./fonts/FuturaStd-Medium.otf") format("opentype"),
      url("./fonts/FuturaStd-Medium.svg") format("svg"),
      url("./fonts/FuturaStd-Medium.ttf") format("truetype"),
      url("./fonts/FuturaStd-Medium.woff") format("woff"),
      url("./fonts/FuturaStd-Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  /*Futura Heavy*/
  @font-face {
    font-family: "FuturaStd-Heavy";
    src: url("./fonts/FuturaStd-Heavy.eot");
    src: url("./fonts/FuturaStd-Heavy.eot?#iefix") format("embedded-opentype"),
      url("./fonts/FuturaStd-Heavy.otf") format("opentype"),
      url("./fonts/FuturaStd-Heavy.svg") format("svg"),
      url("./fonts/FuturaStd-Heavy.ttf") format("truetype"),
      url("./fonts/FuturaStd-Heavy.woff") format("woff"),
      url("./fonts/FuturaStd-Heavy.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  
  /*Futura Condense Bold*/
  @font-face {
    font-family: "FuturaStd-CondensedBold";
    src: url("./fonts/FuturaStd-CondensedBold.eot");
    src: url("./fonts/FuturaStd-CondensedBold.eot?#iefix") format("embedded-opentype"),
      url("./fonts/FuturaStd-CondensedBold.otf") format("opentype"),
      url("./fonts/FuturaStd-CondensedBold.svg") format("svg"),
      url("./fonts/FuturaStd-CondensedBold.ttf") format("truetype"),
      url("./fonts/FuturaStd-CondensedBold.woff") format("woff"),
      url("./fonts/FuturaStd-CondensedBold.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  
  /*Helvetica Regular*/
  @font-face {
    font-family: "Helvetica-Regular";
    src: url("./fonts/Helvetica-Regular.eot");
    src: url("./fonts/Helvetica-Regular.eot?#iefix") format("embedded-opentype"),
      url("./fonts/Helvetica-Regular.otf") format("opentype"),
      url("./fonts/Helvetica-Regular.svg") format("svg"),
      url("./fonts/Helvetica-Regular.ttf") format("truetype"),
      url("./fonts/Helvetica-Regular.woff") format("woff"),
      url("./fonts/Helvetica-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  
  /*Helvetica Condensed*/
  /*@font-face {
    font-family: "HelveticaNeue-Condensed";
    src: url("./fonts/HelveticaNeue-Condensed.eot");
    src: url("./fonts/HelveticaNeue-Condensed.eot?#iefix") format("embedded-opentype"),
      url("./fonts/HelveticaNeue-Condensed.otf") format("opentype"),
      url("./fonts/HelveticaNeue-Condensed.svg") format("svg"),
      url("./fonts/HelveticaNeue-Condensed.ttf") format("truetype"),
      url("./fonts/HelveticaNeue-Condensed.woff") format("woff"),
      url("./fonts/HelveticaNeue-Condensed.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }*/
}


@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

@mixin mq($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == min {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    } @else {
        @warn 'No hay un tamanio #{$width} definido';
    }
}

// Meadia Queries for landscape
@mixin mq-l($width, $type: max){
    $width: map_get($breakpoints, $width);
    @media (#{$type}-width: $width) and (orientation: landscape) {
        @content;
    }
}