.page_dolor-body{
    background-image: url("../images/background/background-desktop.jpg");
    width: 100%;
	min-height: 100vh;
	overflow-x: hidden;
	background-size: 100% 100%;
	background-attachment:fixed;
	background-repeat: no-repeat;
	background-position: center center;
    background-size: cover;
    
}


.slider-item.track{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .track.first, .track.second{
 
        display: flex;
        
        @media screen and (max-width: 713px) {
            flex-direction: column;

        }

        .track-col{

            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: space-around;
            align-items: center;
            margin: 0 .4vmax;

            @media screen and (max-width: 713px) {
                margin: 2em 0;
            }


            &.header-track{
     
                margin: 0;
            }


          

            & figure img {
                width: 100%;
            }
        }

             
        @media screen and (max-width: 713px) {
            flex-direction: column;
        }
    }

    .track.track.first{
        display: flex;
        margin: 0 0 3em 0; 

        @media screen and (max-width: 713px) {
            margin: 0;
        }
     }

    .track.second{
        display: flex;
        margin:  .5em 0 0 0; 

        @media screen and (max-width: 713px) {

            margin: 0 0 1em 0;
        }
     }
}


.track-col{
    display: flex;

    h3{
        font-size: .85em;
        display: block;
        color: #5e1055;
        text-align: center;
        height: auto;
        

         

        &.header-track{
            font-family: "FuturaStd-CondensedBold", sans-serif;
            color: #5e1055;
            display: block !important;
            font-size: 3vw;
            margin: 0;
            text-align: start;
            line-height: 1.2;
            display: block;
            height: auto;

            @media screen and (max-width: 713px) {
                font-size: 2em ; 
                margin: 1em 0 .5em 0;
                height: auto;
            } 

        }

        @media screen and (max-width: 1024px){
            font-size: .8em;
            height: auto;
        }

        @media screen and (max-width: 713px) {
            font-size: 1em ; 
            height: auto;
        }
    }

    figure{
        margin: .5em 0;

        img{
            width: 90%;
        }
    }


    p{
      

        color: #000;
        font-size: .63em;
        display: block;
        height: auto;
        text-align: center;

        @media screen and (min-width: 1200px) {
            font-size: .75em;
            width: 85%;
        }

        @media screen and (max-width: 713px) {
            font-size: .9em ; 
            width: 70%;
            line-height: 1.2;
            height: auto;
        } 
    }
}



.slider-item.column-2.hidden{
    
    @media screen and (min-width: 1200px) {
        font-size: .75em;
        width: 85%;
    }   
}



.track-col.header{
    justify-content: center !important;
    display: block !important;

    
}

.title-article.track{
    font-size: 2.8em;
    height: auto !important;
    
    @media screen and (max-width: 713px) {
        text-align: start !important;
    }
}
