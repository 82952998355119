
	footer{
		display: flex;
		align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        margin:  1.5em 0 0 0;

		@media screen and (max-width: 1024px) {
            padding: 0 10% 1em 10%;
            margin: 0 0 0 0;
            position: relative;
        }

        @media screen and (max-height: 713px) {
            position: relative;
        }

        @media screen and (max-width: 713px) {
            position: relative;
            margin:  1em 0 0 0;
        }


		.footer-text{
			/*bottom: 30px;*/
			color: #87868a;
			font-size: .8em;
            line-height: 1.4;
            line-height: 1.3;
			text-align: center;
			position: relative;
            padding-bottom: 10px;
            width: 80%;
            font-family: Helvetica-Regular;
            z-index: 9999;

			@media screen and (max-width: 1024px) {
				padding: 0;
				font-size: .8em;
				line-height: 1.3;
				letter-spacing: 1.1px;
            }

            @media screen and (max-width: 713px){
                width: 100%;
            }

            
            p{
                font-size: .73vmax;
                margin: 0 0 .5em 0;
                @media screen and (max-width: 1024px) {
                    font-size: .8em;
                }
            }

            .text-extend{
                @media screen and (max-width: 713px) {
                    display: none;
                }
            }
	
		}
    }
    

    .footer_img_wrap{
        width: auto;
        height: auto;

        img{
            width: 12vmax;
            position: absolute;
            right: 0;
            bottom: 0;

            
            @media screen and (max-width: 1024px) {
                width: 15vmax;
            }


            @media screen and (max-width: 714px) {
                width: 100px;
            }
        }
            
    }