
.container.content-second.video{
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1024px) {
        justify-content: space-between;
        flex-grow: 1;
        justify-content: space-around;
    }


    @media screen and (max-width: 713px) {
        flex-direction: column;
        justify-content: center;
    }

    &.index_video{
        width: 100%;
        justify-content: flex-end;
        .video__responsive__wrap iframe{
            left: 7em;
            width: 80%;
            @media only screen and (max-width: 1024px){
                left: 0;
                width: 100%;
                height: 100%;
                margin-top: 15%;
            }
        }
    }
}

.video-responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16/9 ratio */
    padding-top: 30px; /* IE6 workaround*/
    height: 0;
    overflow: hidden;
    }
    
    .video-responsive iframe,
    .video-responsive object,
    .video-responsive embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    }

    .video__responsive__wrap{
        width: 80%;
        max-width: 1080px;
        min-height: 600px;
        height: 100%;
        max-height: 961px;
        position: initial;
        margin-right: 2.5vw;
        z-index: 9;

        @media screen and (max-width: 1024px){
            margin: 0;
        }

        @media screen and (max-width: 713px) {
           width: 100%;
           height: 21px;
           min-height: 350px;
        }

        
        

            iframe {
                width: 100%;    
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                border-radius: 20px;

                
            @media screen and (max-width: 713px) {
                width: 100%;
                height: 321px;
                border-radius: 0;
            }
    
            }   
    }