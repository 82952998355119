.logo__tq {
    align-items: center;
    height: auto;
    z-index: 9999;
    position: relative;
    display: flex;
    left: 5%;
    // @media (max-width: 1024px){
    //     width: 6em;
    // }
    figure {
        width: 50px;
        height: 50px;
        @media (max-width: 1024px) {}
    }
}

body.carousel.contacto {
    background-image: url("../images/background/background-ibuflash-forte.jpg");
    min-height: 100vh;
    height: auto;
}

.container.contacto {
    width: 100%;
    flex-grow: 1;
    padding: 0 7.7% 0 11%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media screen and (min-width: 713px) {
        min-height: 550px;
    }
    @media screen and (max-width: 713px) {
        flex-direction: column-reverse;
        height: auto;
        padding: 0 5%;
    }
    .form.desktop {
        width: 46%;
        height: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 813px) {
            font-size: 10px !important;
            margin: 2em 0;
        }
        @media screen and (max-width: 713px) {
            width: 100%;
            height: auto;
            label.lb_img {
                display: none;
            }
        }
    }
    @media screen and (max-width: 713px) {
        .field_firts input,
        .field_second input {
            width: 100%;
            border: solid #6e0854 1px;
            border-radius: 10px;
            padding: 10px 10px;
            margin: 1em 0;
            background: transparent;
            font-size: 1.3em;
        }
        .form_select .document_select {
            margin-left: 0;
            border: solid #6e0854 1px;
            color: #6e0854;
            font-size: 1.3em;
            font-family: "FuturaStd-CondensedBold";
            margin: 1em 0 0.5em 0;
            padding: 10px 6px;
        }
        .field_message {
            margin: 0;
            width: 100%;
        }
        .select_motivo {
            border-radius: 10px;
        }
        .field_firts input::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #6e0854;
            opacity: 1;
            /* Firefox */
        }
        .field_firts input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: red;
        }
        .field_firts input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: red;
        }
        .document_select {
            right: 0;
        }
        .select_motivo {
            padding: 10px 6px;
            font-size: 1.3em;
        }
        .contacto_enviar input {
            padding: 3% 10%;
        }
    }
    picture {
        width: 51%;
        @media screen and (max-width: 713px) {
            width: 85%;
            margin-bottom: 1em;
        }
        img {
            width: 90%;
            margin: 0 auto;
        }
    }
}

input {
    background-color: transparent;
    border: none;
    border-bottom: #6e0854 solid 1px;
}

.field_firts,
.field_second {
    display: flex;
    margin: 0;
    input {
        min-width: 100px;
        flex-grow: 1;
        margin: 0.8em;
        font-family: "FuturaStd-Medium";
        letter-spacing: 1px;
        font-size: 1.05em;
    }
    input::placeholder {
        color: #b1b2b6;
        font-family: "FuturaStd-CondensedBold";
    }
}

.field_second {
    margin: 0 0 0.8em 50px;
}

.lb_img {
    width: 45px;
    height: auto;
}

.form_header {
    color: #6e0854;
    font-size: 1.5em;
    font-family: "FuturaStd-CondensedBold";
    margin: 1em 0 0.5em 0;
    padding: 5px 0;
}

.field_message {
    width: 90%;
    margin: 0 1em 0 50px;
}

.form_select {
    width: 100%;
    .document_select {
        background-color: transparent;
        border: 0;
        font-size: 1em;
        border-bottom: solid 1px #6e0854;
        min-width: 100px;
        flex-grow: 1;
        margin: 0.8em;
        font-family: "FuturaStd-Medium";
        letter-spacing: 1px;
        font-size: 1.05em;
        margin-left: 3.5em;
        color: #b1b2b6;
        font-family: "FuturaStd-CondensedBold";
        text-transform: uppercase;
        &:focus {
            outline: none !important;
        }
    }
}

textarea {
    border: solid 1px #6e0854;
    font-family: "Helvetica-Regular";
    font-size: 0.8em;
    letter-spacing: 2px;
    border-radius: 10px;
    background-color: transparent;
    width: 100%;
    min-height: 120px;
    max-height: 600px;
    padding: 1em;
}

.input {
    display: inline-block;
    background-color: #6e0854;
    color: #fff;
    margin: 1em auto;
    padding: 0.4em 1.8em;
    font-family: "FuturaStd-Medium";
    font-size: 1.05em;
    letter-spacing: 2px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    @media screen and (max-width: 1024px) {
        display: block;
        margin-top: 2em;
        margin-left: 5em;
        /*  width: 80px; */
    }
    @media screen and (max-width: 713px) {
        display: block;
        margin-top: 0;
        margin-left: auto;
        width: auto;
    }
    &:hover {
        transform: scale(1.07);
    }
}

.form.mobile {
    display: none;
    flex-direction: column;
    @media screen and (max-width: 713px) {
        display: block;
    }
}

.field_mobile {
    background-color: transparent;
    margin: 0 0 15px 0;
    input,
    select {
        width: 100%;
        border: solid #6e0854 1px;
        border-radius: 10px;
        padding: 10px 10px;
        margin: 1em 0;
        background: transparent;
    }
}

label {
    color: #6e0854;
    font-size: 1.2em;
    font-family: "FuturaStd-CondensedBold";
    margin: 1em 0 0.5em 0;
}

.footer-contact-p-m {
    display: none;
    font-size: 1em !important;
    margin: 1.2em 0 !important;
    >span {
        font-family: "Helvetica-Condensed-md";
    }
    @media screen and (max-width: 713px) {
        display: block;
    }
}


/* /////////// Modal //////////////// */

.submit_content {
    width: 100%;
    margin-left: 2%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .terms_check {
        margin: 5px 0;
    }
}

.contacto_enviar {
    margin-left: 10px;
}

.box {
    width: 40%;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.2);
    padding: 35px;
    border: 2px solid #fff;
    border-radius: 20px/50px;
    background-clip: padding-box;
    text-align: center;
}

.button {
    font-size: 1em;
    padding: 10px;
    color: #fff;
    border: 2px solid #06d85f;
    border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

.button:hover {
    background: #06d85f;
}

#motivo_contacto {
    width: 100%;
    height: 40px;
    margin-top: 2%;
    background-color: transparent;
    border: 0;
    border-bottom: solid 1px #6e0854;
    color: #6e0854;
    font-family: "FuturaStd-Medium";
    font-size: 1.05em;
}

#motivo_contacto:focus {
    outline: none;
}

@media (max-width: 500px) {
    .button_terminos {
        font-size: 15px;
        margin: 8% 0;
    }
}

.select_motivo {
    background-color: transparent;
    width: 100%;
    border-color: #6e0854;
    option {
        font-size: 20px;
    }
    &:focus {
        outline: none;
    }
    @media (max-width: 1024px) {
        font-size: 20px;
    }
}