$grid-size: 80.2%; /*1140px;*/
$grid-tablet: 84.375%;
// $grid-smaller: 62.5%;
// $grid-tablet-landscape: 83%;
// $grid-mobile: 85%;
$login_height: 5.45vh;
$menu_height: 11.59vh;

$header_height: $login_height + $menu_height;

// Colors 

$color_red: #e2212c;
$color_dark_red: #b62431;
$color_black: #000000;
$color_white: #fff;
$color_gray: #f7f7f7;
$color_dark_gray: #1c1c1c;


// Fonts Colors
$purple: #5e1055;
$purple2: #7c3577;
$gray: #ced0d9;


$color_font_lightgray: #999999;
$font-base: 16;
$font_callback: sans-serif;

// Font var
$font-futura-medium: FuturaStd-Medium;
$font-futura-Heavy: FuturaStd-Heavy;
$font-futura-Condensed-Bold: FuturaStd-CondensedBold;


$of_canvas : 50vw;

$breakpoints: (
    "phone":                400px,
    "phone-wide":           480px,
    "phablet":              560px,
    "tablet-small":         640px,
    "phone-landscape":      813px,
    "tablet-wide":          1024px,
    "desktop":              1280px,
    "desktop-wide":         1440px,
    "desktop-ultrawide":    1680px,
);

$padding-10: 0 8%;