.page_dolor-body{
    min-height: 100vh; 
    background-image: url('../images/background/background-ibuflash-forte.jpg');
  
  }

  .page_dolor-body.tipos{
    min-height: 100vh; 
    background-image: url('../images/background/back_tipo.jpg');
  }



  .dolor_section{
    padding: 0 4% 0 8%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

   
    .dolor_article-wrap {
      flex-basis: 40%;
      flex-shrink: 0;

      @media screen and (max-width: 713px) {
        order: 3;
      }
    }
    

    picture{
      flex-grow: 0;
      flex-shrink: 1;

      img, source{
        max-height: 75vh;
      }
      
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 5% 10%;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      height: 75vh;
    }

    
    @media screen and (max-width: 713px) {
      display: block;
      padding: 5% 10%;
      flex-grow: 1;
      justify-content: center;
      height: auto;
    }

  }

        .title-article{
          font-family: "FuturaStd-CondensedBold";
            color: #6f1762;
            font-size: 3.3em;
            line-height: 1.1;
            margin: .5em 0 .3em 0;          

            @media screen and (max-width: 713px) {
              font-size: 2.5em;
            }
        }

        .p-article{
          font-size: .9em;
          line-height: 1.6;
          margin: .5em 0 1em 0;
          color: #000;
          font-family: "Helvetica-Light";

          @media screen and (min-width: 1600px) {
            font-size: 1.1em;
          }
        }

        .sub-article{
          color: #6f1762;
          font-family: "FuturaStd-CondensedBold";
          font-size: 1.3rem;
        }


        .btn_wrap.dolor{
          @media screen and (max-width: 1024px) {
            order: 2;

           /*  .btn_arrow.top, .btn_arrow.bottom{
              display: none;
            } */
          }
        }




        .btn-article.NextButton {
          background-color: #b51b2f;
          padding: .6em .8em;
          border: none;
          display: table;
          border-radius: 5px;
          font-family: "FuturaStd-Medium";
          margin: 1.5em auto 0 auto;

          color: #fff;
          font-size: .8em;
          transition: all .4s ease-in-out;


          text-align: center;
          cursor: pointer;

          &:hover{
            transform: scale(1.04);
          }

          @media screen and (min-width: 1600px) {
            font-size: 1em;
          }

      }


