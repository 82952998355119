body.carousel.compras{
    background-image: url("../images/background/background-compras.png");
    min-height: 100vh;  
    height: auto;  
}

.hidden-in-mobile{
    display: flex;
}
.hidden-in-desktop{
    display: none ;
}

@media (max-width: 1024px){
    /* .logo__tq{
        display: none;
    } */

    .hidden-in-desktop{
        display: flex;
    }
    .hidden-in-mobile{
        display: none ;
    }
    .compras_title{
       text-align: end;
    }
}

.container.compras{
    width: 100%;
    flex-grow: 1;
    padding: 0 7.7%  0  11%;
    /* display: flex; */
    justify-content: space-around;
    align-items: center;
   
    @media screen and (min-width: 1024px) {
        height: 60vh;
        display: flex;
    }

    @media screen and (max-width: 1024px){
        flex-direction: column-reverse;
        height: auto;
        padding: 0 5%;
        height: 100vh;
    }

    .form.desktop{
        width: 46%;
        height: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        
        @media screen and (max-width: 813px){
            font-size: 10px !important;
            margin: 2em 0;
         }


        @media screen and (max-width: 713px){
            width: 100%;
            height: auto;
            display: none

        }
    }

    picture{
        @media screen and (max-width: 768px){
            margin-bottom: 1em;
        }

        img{
            width: auto;
            height: auto;
        }
    }
}

.compras_title{
    font-size: 99px;
    font-family: 'FuturaStd-CondensedBold', sans-serif;
    text-transform: uppercase;
    color: #999999;
    span{
        color: #515151;
    }
}

.compras_text{
    margin-top: 15px;
    border-left: solid 4px #5b5b5f;
    height: 100px;
    padding-left: 10px;
}

.allies{
    width: 45%;
    @media only screen and (max-width: 1024px){
        width: 100%;
        margin: 10% 0;
    }
    .first_allies{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 113px;
        .exito{
            width: 30%;
        }
        .drogueria{
            width: 65%;
        }
    }
    .second_allies{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 5% 0;
        height: 60px;
        .cafam{
            width: 70%;
        }
        .farmatodo{
            width: 50%;
        }
        .rebaja{
            width: 30%;
        }
    }
    .third_allies{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 120px;
        .rappi{
            width: 35%;
        }
    }
}


@media (max-width: 1024px){
    .compras_title{
        font-size: 70px;
        text-align: left;
    }
    .compras_text{
        margin-bottom: 25px;
    }
}

@media (max-width: 320px){
    .container.compras{
        margin: 15% 0;
    }
}


.allies{
    &.hiden-in-mobile{
        display: block;
        @media only screen and (max-width: 1000px){
            display: none;
        }
    }
    &.hiden-in-desktop{
        display: none;
        @media only screen and (max-width: 1000px){
            display: flex;
        }
    }

    &.mobile{
        .first_allies{
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            picture{
                height: 100px;
                @media (min-width: 720px) and (max-width: 1000px){
                    height: 200px;
                }
                img{
                    width: auto;
                    height: auto;
                }
            }
        }
    }
}

@media (max-width: 1024px){
    body.carousel.compras{
        overflow: auto;
    }
}

@media (min-width: 1000px) and (max-width: 1050px){
    .allies_title{
        order: 1;
    }
}

.footer-text{
    &.footer_compras{
        @media (max-width: 900px){
            position: absolute;
            top: 100%;
        }
    }
}