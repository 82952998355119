.btn_video {
    display: inline-flex;
    width: auto;
    text-decoration: none;
    color: #fff;
    padding: .4em .8em;
    background-color: #7c3577;
    border-radius: 5px;
    align-items: center;
    font-size: 1em;
    letter-spacing: 2px;
    margin-top: 2em;
    font-family: "FuturaStd-Medium", sans-serif;
    img {
        margin: 0 7px 0 0;
    }
    &.migran {
        background-color: #426c72;
    }
    &.red {
        background-color: #8d0003;
    }
    &.red_forte {
        background-color: #b51b2f;
    }
}

.lightbox-btn-close {
    background-image: url('../images/icons/cross.png');
    cursor: pointer;
}