body.background-products {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../images/background/background_desktop.jpg");
}

.carousel.background-ibuflash-forte {
    background-image: url("../images/background/background-desktop.jpg");
    @media screen and (max-width: 1024px) {
        justify-content: flex-start;
    }
}

body.carousel {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background-size: 100% 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    /*     height: 100vh; */
}

body.carousel.background-ibuflash-forte {
    background-image: url("../images/background/background-ibuflash-forte.jpg");
}

body.carousel.background-ibuflash-migran {
    background-image: url("../images/background/background-ibuflash-migran.jpg");
}

body.carousel.background-ibuflash-forte-red {
    background-image: url("../images/background/background-ibuflash-forte-red.jpg");
}

.container-grid {
    width: 80%;
    margin: 0 auto;
    .container-menu {
        width: 100%;
        padding: 25px 0;
        display: inline-flex;
        .icon-logo {
            img {
                width: 10em;
                height: auto;
            }
        }
        .menu {
            width: 100%;
            height: 25px;
            display: flex;
            padding-top: 20px;
            padding-right: 70px;
            justify-content: flex-end;
            ul.items_menu {
                margin: 0;
                padding: 0;
                width: 90%;
                display: inline-flex;
                li {
                    flex-grow: 1;
                    list-style: none;
                    position: relative;
                    text-align: center;
                    a {
                        color: #5b5b5f;
                        font-size: 1vw;
                        font-weight: bold;
                        text-decoration: none;
                        text-transform: uppercase;
                        font-family: FuturaStd-Medium;
                        &:hover {
                            color: #622562;
                        }
                        @include mq(desktop,
                        min) {
                            font-size: 0.9vw;
                        }
                    }
                    &:last-child {
                        img {
                            top: -7px;
                            right: -29px;
                            margin-left: 10px;
                            position: absolute;
                            width: 33px;
                        }
                    }
                    &:after {
                        @include mq(desktop,
                        min) {
                            content: ".";
                            position: absolute;
                            top: -7px;
                            left: 0;
                        }
                    }
                    &:first-child {
                        @include mq(desktop,
                        min) {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
                li.point {
                    &:after {
                        content: none;
                    }
                    @include mq(desktop,
                    min) {
                        display: none;
                    }
                }
            }
        }
    }
    .container-products {
        width: 100%;
        height: 75vh;
        @media screen and (max-width: 713px) {
            display: none;
        }
        .products {
            bottom: 20%;
            width: 500px;
            height: 500px;
            @media screen and (min-width: 1580px) {
                bottom: 25%;
            }
            @media screen and (max-width: 1460px) {
                bottom: 18%;
                /* margin-left: -6em !important;
                    right: 5em !important; */
            }
            img {
                width: 100%;
                height: auto;
                max-width: 500px;
                max-height: 500px;
            }
        }
        .product-1 {
            left: 0;
            position: absolute;
            a {
                padding: 10px;
                color: #fff;
                font-size: 1vw;
                border-radius: 10px;
                background: #374e51;
                text-decoration: none;
                text-transform: uppercase;
                font-family: FuturaStd-Medium;
                position: relative;
                bottom: 15%;
                left: 30%;
                z-index: 9;
                @media only screen and (min-width: 1580px) {
                    left: 26%;
                }
                @media only screen and (min-width: 1900px) {
                    left: 19%;
                }
            }
        }
        .product-2 {
            left: 33%;
            position: absolute;
            a {
                padding: 10px;
                color: #fff;
                font-size: 1vw;
                border-radius: 10px;
                background: #622562;
                text-decoration: none;
                text-transform: uppercase;
                font-family: FuturaStd-Medium;
                position: relative;
                bottom: 15%;
                left: 30%;
                z-index: 9;
                @media only screen and (min-width: 1580px) {
                    left: 26%;
                }
                @media only screen and (min-width: 1900px) {
                    left: 19%;
                }
            }
        }
        .product-3 {
            right: 0;
            position: absolute;
            a {
                padding: 10px;
                color: #fff;
                font-size: 1vw;
                border-radius: 10px;
                background: #9d3b38;
                text-decoration: none;
                text-transform: uppercase;
                font-family: FuturaStd-Medium;
                position: relative;
                bottom: 15%;
                left: 30%;
                z-index: 9;
                @media only screen and (min-width: 1580px) {
                    left: 26%;
                }
                @media only screen and (min-width: 1900px) {
                    left: 19%;
                }
            }
        }
    }
    .container-product-info {
        padding-top: 20px;
        .product-title {
            h1 {
                font-size: 1vw;
                color: #87868a;
                font-weight: bold;
                font-family: Helvetica-Regular;
                span {
                    color: #9d3b38;
                }
            }
        }
        .product-info {
            .row {
                padding-top: 30px;
                display: inline-flex;
                .column-1 {
                    width: 27%;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    padding-top: 40px;
                    padding-left: 30px;
                    a {
                        font-size: 1vw;
                        color: #87868a;
                        font-weight: bold;
                        text-decoration: none;
                        padding-bottom: 30px;
                        font-family: Helvetica-Regular;
                        &:hover {
                            color: #9d3b38;
                        }
                        &.active {
                            color: #9d3b38;
                        }
                    }
                }
                .column-2 {
                    width: 50%;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    h2 {
                        color: #9c3030;
                        font-size: 1vw;
                        font-weight: bold;
                        font-family: Helvetica-Regular;
                    }
                    p {
                        color: #000000;
                        font-size: 1vw;
                        font-family: Helvetica-Regular;
                    }
                }
                .column-3 {
                    width: 23%;
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 100px;
                    padding-left: 60px;
                    align-items: flex-end;
                    a {
                        padding-bottom: 20px;
                        &:last-child {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.img_carousel {
    width: 100%;
    @media screen and (max-width: 1024px) {
        flex-direction: row;
        width: 100%;
    }
    @media screen and (max-width: 375px) {
        margin: 0;
    }
}

.btn_wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1024px) {
        flex-direction: row;
        width: 100%;
        margin: 1em 0 2em 0;
        height: auto;
        width: auto;
    }
    @media screen and (max-width: 713px) {
        order: 3;
        margin: 1em 0 1em 0;
    }
    .btn_arrow {
        margin: 0.5em 0;
        width: 30px;
        @media screen and (max-width: 1024px) {
            display: flex;
            flex-direction: row;
            margin: 0 0.5em;
            width: auto;
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 713px) {
            width: auto !important;
        }
        li {
            @media screen and (max-width: 1024px) {
                margin: 0.5em;
                width: auto;
            }
            a {
                margin: 0.5em 0;
                width: 12px;
                height: 12px;
                border: solid 1.5px #7c3577;
                border-radius: 10px;
                display: block;
            }
            .active {
                background-color: #7c3577;
            }
        }
    }
}

.title-section.forte>span {
    color: #7c3577;
}

.prueba_menu {
    color: #5b5b5f;
    font-size: 1vw;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    font-family: FuturaStd-Medium;
    &:hover {
        color: #622562;
    }
}

.items_menu {
    >li.active {
        a {
            color: #7c3577 !important;
        }
    }
}

.btn_disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.container.products>.container-grid {
    @media screen and (max-height: 700px) {
        width: 85%;
    }
}

.sub_wrap {
    .sub-item {
        width: 100%;
    }
}


/* New Products */

.main_products {
    overflow: hidden;
}

.productos {
    display: flex;
    justify-content: center;
    margin-top: -2em;
    margin-bottom: 2em;
    flex-wrap: wrap;
    @media only screen and (max-width: 768px) {
        margin-bottom: 5em;
    }
    &__container {
        width: 400px;
        height: 400px;
        @media only screen and (max-width: 420px) {
            width: 400px;
            height: 400px;
        }
        @media only screen and (max-width: 375px) {
            width: 300px;
            height: 300px;
        }
        figure {
            width: 400px;
            height: 400px;
            @media only screen and (max-width: 420px) {
                width: 350px;
                height: 350px;
                margin: auto;
            }
            @media only screen and (max-width: 375px) {
                width: 270px;
                height: 270px;
                margin: auto;
            }
        }
        .img_back {
            background-size: contain;
            display: flex;
            align-items: center;
        }
        .link_content {
            text-align: center;
            /* position: relative;
            bottom: 10%; */
            a {
                padding: 10px;
                color: #fff;
                font-size: 0.9em;
                border-radius: 10px;
                text-decoration: none;
                text-transform: uppercase;
                font-family: FuturaStd-Medium;
                &.migran_back {
                    background: #374e51;
                }
                &.ultra_back {
                    background: #622562;
                }
                &.forte_back {
                    background: #9d3b38;
                }
            }
        }
        .sombra_content {
            width: 400px;
            height: 400px;
            background-size: contain;
            background-repeat: no-repeat;
            @media (max-width: 420px) {
                width: 350px;
                height: 350px;
                margin: auto;
            }
            @media (max-width: 375px) {
                width: 270px;
                height: 270px;
                margin: auto;
            }
        }
    }
}