
.container{
    @media screen  and (max-width: 713px){
    }
}

.footer-text{
   z-index: 99;
}

.container.products{
    @media screen  and (min-width: 713px){
        display: flex;
    }
}

.container-grid .container-menu {

    display: none;
    @media screen  and (min-width: 1025px){
        display: flex;
        align-items: center;
    }
}

.container-grid .container-products {

    @media screen  and (min-width: 713px){
        display: flex;
        height: 65vh;
    }

    
}

.container-grid .container-products .products {
  

      @media screen  and (max-width: 1024px){
        bottom: 40% !important;
    }
}