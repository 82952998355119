.menu-desktop{
    display: flex;
}

.header-mobile{
    display: none;
    width: 100%;
    
}

.container-grid.menu{
    @media screen and (max-width: 1180px) {
        width: 90%;
    }
}

@media screen and (max-width: 1024px){

    .header{
        display: none !important;
        
    }

    .header-mobile{
        display: flex;
        position: relative;
        width: 100%;
     
        background-color: $gray; 
        z-index: 10000;
        padding: 1.5em 0;
        

        
        .logo{
            width: 100%;           
            display: flex;
            justify-content: space-between;
            padding: 0 8%;
            }
        

        .logo__container{
            width: 150px;
        }

        .hamburger_mobile{
            display: flex;

            & > a {
                display: block;
                width: 2rem;
                height: 2rem;
            }

            .icon-car{
                img{
                    width: 100%;
                }
            }

            .hamburger{
                margin: 0 0 0 2em;
                padding: 1em;
                 background: linear-gradient(to bottom,
                $purple2, $purple2 15%,  
                $gray 15%,  $gray 40%, 
                $purple2 45%, $purple2 60%, 
                $gray 60%,  $gray 85%, 
                $purple2 85%, $purple2 100%
                
                )
            }
        }

        .hamburger__mobile__image{
            width: 40px;
            height: auto;
        }
    }

    .navigation__list{
        @media screen and (max-width: 713px) {
            height: 100vh;
        }
    }




    .navigation_mobile{
        height: auto;
        padding: 0 2em;
        width: 100%;


        .menu-text-content{
            width: 100%;
            display: block;
            margin: 1em 0 0 0;        
        }
        
        .menu_text{
            font-size: 24px;
            font-weight: bold;
            margin: .5em 0;

          /*   &::after{
                content: "";
                display: block;
                position: absolute;
                width: 60px;
                height: 2px;
               
            } */
        }

        .navigation__list{
            margin: 3em 0;



            .navigation__item{
                padding: .4em 0;

                &.active{
                    background-color: #cac9d4;
                }

                &:active{
                    background-color: #cac9d4;
                }
            }
            
            .navigation__subitem{
                margin:.8em 0;
                padding: 0 0 0 13%;   

                a{
                    text-decoration: none;
                    color: #5b5b5f;
                    font-family: "FuturaStd-Medium";
                }
            }

            .subitem__wrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 8%;
            }

            .subitem_arrow_wrap{
                display: flex;
                justify-content: flex-end;
               display: inline;
            }

           

            .subitem__arrow{
                padding: 10px;
                width: 40px;
                height: auto;
                z-index: 1000;
                transition: all .3s ease-out;
            }

            .subitem__link{
                display: flex;
            }

            .subitem__icon{
                width: 25px;
                height: auto;
                display: block;
                margin: 0 .5em 0 0;
            }
        }
    }
    .wrap__btn{
        width: 100%;
        text-decoration: none;

        color: #5b5b5f;
        font-family: "FuturaStd-Medium";
        line-height: 1.3;
        width: 80%;
        margin: 0;
        padding: 10px 0;

        &.active{
            color: #622562;
            font-family: "FuturaStd-Heavy";
        }
     }

}


.header_active{
    border-bottom: 1.5px black solid;
    padding-bottom: 5px;
}

.nav__link{
    & > a{

    }
}

.header_sub_active{
    border-bottom: 2px black solid;
}

.header_blue{
    background-color: rgba(95,160,256, 0.95) !important;
}

.header_green{
    background-color: rgba(135,198,102, 0.95) !important;
}

.white{
    color: #fff !important;
}   







.item{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
   

    a{
        margin-bottom: 5px;

        &.active{
            color: #622562 !important;
        }
    }


    img{

        display: inline-block;
        width: 65%;
    }
}



.translate_arrow{
    transform: rotate(180deg);
}





/* .item_menu_car::after{
    content: "";
    right: 0;
    height: 30px;
    width: 30px;
    bottom: 0;
    opacity: 1;
    position: absolute;
    background-image:  url(../images/icons/icon-car.png);
    background-size: contain;
    background-repeat: no-repeat;
} */









.sub_wrap{
    width: 100%;
    display: none;
    position: absolute;
    top: 1.5em;
}

.sub-item{
    margin-top: 10px;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    margin-left: 13%;

    position: absolute;
    

    > a {
        margin: 5px 0 0 0;
        font-size: .7vw !important;

        @media screen and (min-width: 1440px) {
            margin: 8px 0 0 0;
            width: 105%;
            text-align: left;
        }
    }
}

.item:hover > .sub_wrap{
    display: inline-block;
    z-index: 99999;
   }



.container-grid.mobile{
    width: 80%;
    align-items: center;

    @media screen and (max-width: 1200px) {
        // width: 90%;
    }
}

.container-grid .container-menu .menu{
   padding: 0;
}

.subitem__link.active{
    color: #622562 !important;
    font-family: "FuturaStd-Heavy" !important;
}


li.item{
    img{
        visibility: hidden;
    }

    &:hover > img{
       visibility: visible;
    }
}